.app{
  width: 100%;
  margin: auto;
  background-color: white;
  padding-top: 0;
  margin-top: 0;
  /* color: pink; */
  /* background-image: url('/public/images/bck.jpg'); */

}
/* @media (min-width: 1100px) {
  .app{

  }
}
@media (min-width: 769px) and (max-width: 1099px){
  .app{
    width: 90%;
  }
}
@media (max-width: 768px) {
  .app{

  }
} */
@media (min-width: 720px){
    .app{
      width: 40%;
    }
}