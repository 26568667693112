.head section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.head section h1{
    font-size: 35px;
    font-family: Pro;
}
.head section span{
    display: flex;
    gap: 1rem; 
    margin-right: 1rem;
    align-items: center;
    position: relative;
}
.head section span img{
    width: 30px;
}
.head{
    padding-inline: 1rem;
    margin: 0;
    margin-top: -3rem;
    /* border-bottom: 1px solid gainsboro; */
}
.head small{
    background-color: red;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.head #likes{
    height: 22px;
    width: 22px;
    border-radius: 50%;
    font-size: 8px;
    font-weight: 600;
    position: absolute;
    top: -30%;
    left: 15%;
}
.head #text{
    height: 22px;
    width: 22px;
    border-radius: 50%;
    font-size: 8px;
    position: absolute;
    top: -30%;
    left: 85%;
}