.reels{
 padding: 10px;
 overflow-x: hidden;

}
.reels h1{
    font-size: 16px;
}
.reels main{
    display: flex;
    width: 100vw;
    /* height: 60vw; */
    gap: 5px;
}
.reels main div{
    width: 40%;
    height: 28vh;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 5px;
}
.reels main div video{
    width: 110%;
    /* height: 10%; */
    background-color: black;
}
@media (min-width: 720px){
    .reels main div{
        width: 12%;
        height: 43vh;
        overflow-x: hidden;
        overflow-y: hidden;
        border-radius: 5px;
    }
}