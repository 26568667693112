.navStory{
    display: flex;
    flex-direction: row;
    /* gap: 1.5rem; */
    gap: 0;
    justify-content: space-around;
    margin-left: 0rem;
    overflow-x: hidden;
    margin-bottom: -.5rem;
}
.navStory main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.navStory img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: contain;
    border: 3px solid rgb(185, 103, 103);
    border: 3px solid pink;
}
.navStory main p{
    font-weight: bold;
    margin-top: -.1rem;
}