body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: 80%; */
  padding-top: 2rem;
  margin: auto;
  box-sizing: border-box;
  background-color: rgb(18, 18, 18);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Pro;
  src: url(./../public/fonts/instagram-new/Demo_Fonts/Fontspring-DEMO-blue_vinyl_bold_ps_ot.otf);
}
@font-face {
  font-family: Six;
  src: url(./../public/fonts/Noto_Sans/NotoSans-VariableFont_wdth\,wght.ttf);
}