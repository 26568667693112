*::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track */
    width: 4px;
    height: 4px;
  }
  
  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: #888; /* Color of the handle */
  }
  
  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the handle on hover */
  }
  .comp{
    /* border: 1px solid black; */
    margin: auto;
    margin-bottom: 1rem;
    overflow-x: hidden;
    /* margin-top: -2rem; */
}
.comp h1{
    font-family: Six;
    font-size: 20px;
}
.comp .top{
    display: flex;
    align-items: center;
    gap: .4rem;
    padding: 5px;
}
.comp .top .topLcation{
    /* border: 1px solid; */
    display: flex;
    flex-direction: column;
}
.comp .top .topLcation small{
    margin-top: -.3rem;
}
.comp .top h1{
    margin: 0;
    padding: 0;
    font-size: 16px;
}
.comp .profile img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: contain;
    border: 1px solid black;
}
.comp .bottom .slides1{
    display: flex;
    overflow-x: scroll;
    width: 100%;
}
/* .comp .bottom{
} */
.mainVid{
    background-color: black;
    width: 100% !important;
}
.comp .bottom .vids {
    display: flex;
    justify-content: center;
    /* height: 60vh; */
    width: 100vw;
}
.comp .bottom .vids div{
    width: 100%;
    display: flex;
    align-items: flex-start;
    /* object-fit: cover; */
}
.comp .bottom .vids div video{
    width: 100vw;
    /* height: 110%; */
}
.comp .bottom .imagesSLides{
    display: flex;
    width: 100%;
    overflow-x: hidden;
    background-color: black;
}
.comp .bottom .imagesSLides div{
    display: flex;
    overflow-x: hidden;
    min-width: 100%;
    height: 100vh;
    animation: slideAnimation 15s infinite;
    animation-delay: 4s;
}
.comp .bottom .imagesSLides img{
    width: 100%;
    object-fit: cover;
}
#narr{
    background-image: url(/public/images/narr.jpg);
    backdrop-filter: blur(10px);
    background-size: cover;
}
#nomics{
    background-image: url(/public/images/nomics.PNG);
    background-size: contain;
    backdrop-filter: blur(10px);
}
#ca{
    border: 1px solid;
    width: 90%;
    word-break: break-all;
}
.comp .bottom .imagesSLides h2{
    padding: 10px;
}
.comp .bottom .imagesSLides button{
    /* width: ; */
    border: none;
    padding: 5px 25px;
    border-radius: 1px;
}
#nomics{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}
.comp .bottom main span{
    padding: 10px;
    background-color: pink;
}
.comp .bottom main span h1{
    padding: 0 !important;
    margin-top: 0rem;
    margin-bottom: 0rem;
}
.comp a{
    text-decoration: none;
    color: black;
}

/* .comp .bottom .mainimg{
    width: 100%;
    object-fit: cover;
    animation: slideAnimation 12s infinite;
    animation-delay: 3s;
} */

@keyframes slideAnimation {
  0%, 10% {
    transform: translateX(0);
  }
  32% {
    transform: translateX(-100%);
  }
  54% {
    transform: translateX(-200%);
  }
  76% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}
.comp .bottom div .reactions{
    display: flex;
    gap: .2rem;
    margin-left: 10px;
    margin-top: 4px;
    align-items: center;
}
.comp .bottom div .reactions img:first-child{
    width: 30px;
}
.comp .bottom div .reactions img{
    width: 25px;
}
.comp .bottom small{
    margin-left: 10px;
}
.comp .bottom p{
    margin-left: 10px;
    font-size: 16px;
}

@media (min-width: 720px) {
    .mainVid{
        background-color: black;
        width: 40% !important;
    }
  } 

